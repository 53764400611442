import Header from "../components/Header";

function ErrorPage() {
  return (
    <div className="container">
        <Header />
        <main className="form-container error-page">
          <div className="form-data thank-you-data">
            <h1>Ups! Etwas ist schief gelaufen!</h1>
            <p>Die Seite konnte nicht geladen werden.</p>
          </div>  
        </main>
    </div>
  )
}

export default ErrorPage;