import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FormContextProvider } from "./context/form-context";
import BusinessInfo from "./pages/BusinessInfo";
import FactoringInfo from "./pages/FactoringInfo";
import FactoringKond from "./pages/FactoringKond";
import Documents from "./pages/Documents";
import Overview from "./pages/Overview";
import ThankYou from "./pages/ThankYou";
import Root from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";
import { AnimatePresence } from "framer-motion";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <FactoringInfo /> },
      { path: "/factoring-kond", element: <FactoringKond /> },
      { path: "/business-info", element: <BusinessInfo /> },
      { path: "/documents", element: <Documents /> },
      { path: "/overview", element: <Overview /> },
      { path: "/thank-you", element: <ThankYou /> },
    ],
  },
]);

function App() {
  return (
    <AnimatePresence>
    <FormContextProvider>
        <RouterProvider router={router} />
    </FormContextProvider>
    </AnimatePresence>
  );
}

export default App;
