import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import formContext from "../context/form-context";
import { CheckmarkSharp } from 'react-ionicons';
import axios from 'axios';

function FactoringInfo() {
  const { customerCount, umsatz, selectedInvoiceDays, selectedBranch, selectedCountry, getStepTwoComplete } = useContext(formContext);

  var statusBew = true;

  axios.get('https://antrag-backend.teba-kreditbank.de/public/api/getbranch/'+selectedBranch.value)
      .then(function (response) {
        console.log(response);
        console.log(response.data[0].locked);
        var brancheErrStr = '';
        var umsatzErrStr = '';
        var invoiceErrStr = '';
        var countryErrStr = '';

        if(response.data[0].locked === 1){
          statusBew = false;
          brancheErrStr = 'Ihre ausgewählte Branche wird nicht unterstützt.';
          document.querySelector('.errorBranche').innerText = brancheErrStr;
          document.querySelector('.errorBranche').classList.remove('hide');
        }

        var umsatzTmp = umsatz.replace('.', '');
        if(umsatzTmp < 50000 || umsatzTmp > 20000000){
          statusBew = false;
          if(umsatzTmp < 50000){
            umsatzErrStr = 'Ihr Vorjahresumsatz liegt unter 50.000 €.';
          }else if(umsatzTmp > 20000000){
            umsatzErrStr = 'Ihr Vorjahresumsatz liegt über 20 Millionen €.';
          }
          document.querySelector('.errorUmsatz').innerText = umsatzErrStr;
          document.querySelector('.errorUmsatz').classList.remove('hide');
        }
      
        if(selectedInvoiceDays.value === 6){
          statusBew = false;
          invoiceErrStr = 'Ihre durchschnittliche Zahldauer der Rechnungen liegt über 150 Tagen.';
          document.querySelector('.errorInvoice').innerText = invoiceErrStr;
          document.querySelector('.errorInvoice').classList.remove('hide');
        }

        if(selectedCountry.value === 3){
          statusBew = false;
          countryErrStr = 'Ihr Firmensitz liegt leider außerhalb Deutschlands oder Österreichs.';
          document.querySelector('.errorCountry').innerText = countryErrStr;
          document.querySelector('.errorCountry').classList.remove('hide');
        }

        console.log(statusBew);

        if(statusBew === false){
          document.querySelector('.traffic-light-green').classList.add('traffic-light-inactive');
          document.querySelector('.traffic-light-orange').classList.add('traffic-light-inactive');
          document.querySelector('.failBew').classList.remove('hide');
        }else{
          document.querySelector('.traffic-light-orange').classList.add('traffic-light-inactive');
          document.querySelector('.traffic-light-red').classList.add('traffic-light-inactive');
          document.querySelector('.successBew').classList.remove('hide');
          document.querySelector('.container-vorteile').classList.remove('hide');
          document.querySelector('.submit-btn').classList.remove('hide');
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });

  
  const navigate = useNavigate();

  if(customerCount === ''){
    navigate('/');
  } 

  
  function submitHandler(e){
    e.preventDefault();
    getStepTwoComplete();
    navigate('/business-info');
  }

  return <main className="form-container">
    <form className="form" onSubmit={submitHandler}>
      <div className="form-data">
        <h1>Quick-Check Ihrer Factoring-Anfrage</h1>
        <p>Anhand Ihrer Informationen zu Ihrem Factoring-Bedarf prüfen wir, ob Ihr Antrag für das Factoring-Programm der TEBA Kreditbank geeignet ist.</p>

        <div className="box-traffic-light">
          
          <div className="traffic-light-circles">
            <div className="traffic-circle traffic-light-green"></div>
            <div className="traffic-circle traffic-light-orange "></div>
            <div className="traffic-circle traffic-light-red "></div>
          </div>
          <div className="factKondInfoBox">
            <div className="successBew hide">
              <p>Sehr gut! Vervollständigen Sie Ihren Antrag im nächsten Schritt.</p>
              
              <h3>Anmerkung</h3>
              <p>Ihre Forderungen erfüllen unsere Kriterien. Gerne erstellen wir Ihnen ein individuelles Factoring-Angebot.</p>
            </div>
            <div className="warningBew hide">
              <p>Sehr gut! Im nächsten Schritt können Sie Ihren Antrag vervollständigen.</p>
              
              <h3>Anmerkung</h3>
              <p>Unsere Erstbewertung hat ergeben, dass Ihre Forderungen für den Ankauf geeignet sind, sodass wir Ihnen gerne ein Factoring-Angebot unterbreiten möchten.</p>
            </div>
            <div className="failBew hide">
              <p>Schade! Leider können wir Ihnen kein Factoring-Angebot unterbreiten.</p>
              <p className="errorBranche hide"></p>
              <p className="errorUmsatz hide"></p>
              <p className="errorInvoice hide"></p>
              <p className="errorCountry hide"></p>
            </div>
          </div>
        </div>

        <div className="container-vorteile hide">
          <h3 className="par-teba-blue">Ihre Vorteile</h3>
          <ul className="list-vorteile">
            <li><span className="vorteile-text">Feste persönliche Ansprechpartner</span></li>
            <li><span className="vorteile-text">All-inclusive-Gebühren im Skontobereich</span></li>
            <li><span className="vorteile-text">Keine versteckten Kosten</span></li>
            <li><span className="vorteile-text">Ankauf von Abschlagszahlungen</span></li>
            <li><span className="vorteile-text">Ankauf von Rechnungen an private, gewerbliche und öffentliche Kunden</span></li>
            <li><span className="vorteile-text">Keine Information an Debitoren über Forderungsverkauf</span></li>
            <li><span className="vorteile-text">Einreichung der Rechnung auf Ihrem bevorzugtem Weg</span></li>
          </ul>
        </div>

      </div>  
      <div className="form-submit">
        <div><Link to='/' className="cool-gray">Zurück</Link></div>
        <button type="submit" className="submit-btn hide">Weiter</button>
      </div>
    </form>
  </main>
}

export default FactoringInfo;
