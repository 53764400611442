import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import formContext from "../context/form-context";
import { PersonOutline, MailOutline, LocationOutline, CallOutline } from 'react-ionicons'

function BusinessInfo() {
  const { getStepThreeComplete, getName, getAdress, getZipcode, getPlace, getEmail, getPhone, getPartnerName } = useContext(formContext);
  const { customerCount, name, adress, zipcode, place, email, phone, partnerName, selectedCountry } = useContext(formContext);

  const navigate = useNavigate();

  if(customerCount === ''){
    navigate('/');
  } 

  
  function submitHandler(e){
    e.preventDefault();

    var zipErrorString = '';
    var zipCheck = false;
    var tmpZipcode = zipcode.replace(' ', '');

    if(selectedCountry.value === 1){
      if(tmpZipcode.length === 5){
        zipCheck = true;
      }else{
        zipErrorString = 'Die eingegebene PLZ ist keine gültige deutsche Postleitzahl. (z.B. 12345)';
      }
    }else if (selectedCountry.value === 2){
      if(tmpZipcode.length === 4){
        zipCheck = true;
      }else{
        zipErrorString = 'Die eingegebene PLZ ist keine gültige österreichische Postleitzahl. (z.B. 1234)';
      }
    }

    if(zipCheck){
      const inputs = document.querySelectorAll('input');
      let validArr = [];
      inputs.forEach(input => {
        if(input.value === ''){
          input.style.borderColor = 'var(--strawberry-red)';
        } else {
          validArr.push('valid')
          input.style.borderColor = 'var(--valid-green)';
        }
      })

      console.log(validArr);
      
      if(validArr.length >= 7){
        getStepThreeComplete();
        navigate('/documents');
      }
    }else{
      document.querySelector('#zipcode').style.borderColor = 'var(--strawberry-red)';
      document.querySelector('.zipcodeErrorSpan').classList.remove('hide');
      document.querySelector('.zipcodeErrorSpan').innerText = zipErrorString;

    }
    
  }

  return <main className="form-container">
    <form className="form" onSubmit={submitHandler}>
      <div className="form-data">
        <h1>Starten Sie jetzt mit Ihrem Factoring-Antrag!</h1> 
        <p>Bitte erfassen Sie Angaben zu Ihrem Unternehmen.</p>
        <div className="input-div">
          <label htmlFor="name"><span>Firmenname*</span> <span>&nbsp;</span></label>
          <input type="text" id="name" placeholder="Muster GmbH" onChange={getName} value={name}/>
        </div>
        <div className="input-div">
          <label htmlFor="adress"><span>Adresse*</span> <span>&nbsp;</span></label>
          <input type="text" id="adress" placeholder="Musterstraße 1" onChange={getAdress} value={adress}/>
          <LocationOutline cssClasses="inputIcon" title={"LocationIcon"} />
        </div>
        <div className="input-div">
          <label htmlFor="zipcode"><span>PLZ* <span className="zipcodeErrorSpan hide"></span></span> <span>&nbsp;</span></label>
          <input type="text" id="zipcode" placeholder="12345" onChange={getZipcode} value={zipcode}/>
          <LocationOutline cssClasses="inputIcon" title={"LocationIcon"} />
        </div>
        <div className="input-div">
          <label htmlFor="place"><span>Ort* </span> <span>&nbsp;</span></label>
          <input type="text" id="place" placeholder="Musterstadt" onChange={getPlace} value={place}/>
          <LocationOutline cssClasses="inputIcon" title={"LocationIcon"} />
        </div>
        <div className="input-div">
          <label htmlFor="email"><span>E-Mail*</span> <span>&nbsp;</span></label>
          <input type="email" id="email" placeholder="max.mustermann@muster.de" onChange={getEmail} value={email}/>
          <MailOutline cssClasses="inputIcon" title={"MailIcon"} />
        </div>
        <div className="input-div">
          <label htmlFor="number"><span>Telefon*</span> <span>&nbsp;</span></label>
          <input type="text" id="number" placeholder="+49 123456789" onChange={getPhone} value={phone}/>
          <CallOutline cssClasses="inputIcon" title={"PhoneIcon"} />
        </div>
        <div className="input-div">
          <label htmlFor="partnername"><span>Ansprechpartner*</span> <span>&nbsp;</span></label>
          <input type="text" id="partnername" placeholder="Max Mustermann" onChange={getPartnerName} value={partnerName}/>
          <PersonOutline cssClasses="inputIcon" title={"PersonIcon"} />
        </div>

        <p className="warningMandatory">Alle mit * gekennzeichneten Felder sind Pflichtfelder!</p>
      </div>

      
      <div className="form-submit">
        <div><Link to='/factoring-kond' className="cool-gray">Zurück</Link></div>
        <button type="submit" className="submit-btn">Weiter</button>
      </div>
    </form>
  </main>
}

export default BusinessInfo;
