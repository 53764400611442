import React, { useContext }  from 'react'
import { NavLink } from 'react-router-dom';
import formContext from "../context/form-context";

function Header() {
  const { stepOneComplete, stepTwoComplete, stepThreeComplete, stepFourComplete, stepFiveComplete } = useContext(formContext);

  return (
    <header>
        <nav>
            <ul>
            <li>
                  <NavLink to='/' onClick={ (event) => event.preventDefault() } className={
                    stepOneComplete
                    ? 'stepCompleted'
                    : 'stepNotCompleted'
                  }>1</NavLink>
                  <div className="link-info">
                    <div className="step-name">Ihr Factoring-Vorhaben</div>
                  </div>
                </li>
                <li>
                  <NavLink to='/factoring-kond' onClick={ (event) => event.preventDefault() } className={
                    stepTwoComplete
                    ? 'stepCompleted'
                    : 'stepNotCompleted'
                  }>2</NavLink>
                  <div className="link-info">
                    <div className="step-name">Ihre Erstindikation</div>
                  </div>
                </li>
                <li>
                  <NavLink to='/business-info' onClick={ (event) => event.preventDefault() } className={
                    stepThreeComplete
                    ? 'stepCompleted'
                    : 'stepNotCompleted'
                  }>3</NavLink>
                  <div className="link-info">
                    <div className="step-name">Ihr Unternehmen</div>
                  </div>
                </li>
                <li>
                  <NavLink to='/documents' onClick={ (event) => event.preventDefault() } className={
                    stepFourComplete
                    ? 'stepCompleted'
                    : 'stepNotCompleted'
                  }>4</NavLink>
                  <div className="link-info">
                    <div className="step-name">Dokumente</div>
                  </div>
                </li>
                <li>
                  <NavLink to='/overview' onClick={ (event) => event.preventDefault() } className={
                    stepFiveComplete
                    ? 'stepCompleted'
                    : 'stepNotCompleted'
                  }>5</NavLink>
                  <div className="link-info">
                    <div className="step-name">Zusammenfassung</div>
                  </div>
                </li>
            </ul>
            <div className='tebaSquare'></div>
        </nav>
    </header>
  )
}

export default Header