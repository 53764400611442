import { createContext, useState } from "react";

const formContext = createContext();

export const FormContextProvider = ({ children }) => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDateMonthYear, setSelectedDateMonthYear] = useState(null);
  const [selectedDateMonthYearTmp, setSelectedDateMonthYearTmp] = useState(null);
  const [customerCount, setCustomerCount] = useState('');
  const [umsatz, setUmsatz] = useState('');
  const [selectedInvoiceDays, setSelectedInvoiceDays] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adress, setAdress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [place, setPlace] = useState('');
  const [partnerName, setPartnerName] = useState('');

  const [fileBilanz, setFileBilanz] = useState('');
  const [fileBwa, setFileBwa] = useState('');
  const [fileSumSal, setFileSumSal] = useState('');
  const [fileOpos, setFileOpos] = useState('');

  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [stepTwoComplete, setStepTwoComplete] = useState(false);
  const [stepThreeComplete, setStepThreeComplete] = useState(false);
  const [stepFourComplete, setStepFourComplete] = useState(false);
  const [stepFiveComplete, setStepFiveComplete] = useState(false);
  
  function getStepOneComplete(){
    setStepOneComplete(true);
  }

  function getStepTwoComplete(){
    setStepTwoComplete(true);
  }

  function getStepThreeComplete(){
    setStepThreeComplete(true);
  }

  function getStepFourComplete(){
    setStepFourComplete(true);
  }

  function getStepFiveComplete(){
    setStepFiveComplete(true);
  }

  function getFileBilanz(e){
    setFileBilanz(e);
  }

  function getFileBwa(e){
    setFileBwa(e);
  }

  function getFileSumSal(e){
    setFileSumSal(e);
  }

  function getFileOpos(e){
    setFileOpos(e);
  }

  function getName(e){
    setName(e.target.value);
  }

  function getEmail(e){
    setEmail(e.target.value);
  }

  function getPhone(e){
    setPhone(e.target.value);
  }

  function getAdress(e){
    setAdress(e.target.value);
  }

  function getZipcode(e){
    setZipcode(e.target.value);
  }

  function getPlace(e){
    setPlace(e.target.value);
  }

  function getPartnerName(e){
    setPartnerName(e.target.value);
  }

  function selectBranch(e){
    setSelectedBranch({ label: e.label, value: e.value });
  }

  function getCustomerCount(e){
    setCustomerCount(e.target.value);
  }

  function getUmsatz(e){
    setUmsatz(e.target.value);
  }

  function selectDateMonthYear(e){
    var selDate = new Date(e);
    var tmpMonth = selDate.getMonth();
    var tmpYear = selDate.getFullYear();
    setSelectedDateMonthYear({ month: tmpMonth, year: tmpYear });
    setSelectedDateMonthYearTmp(selDate);
  }

  function selectInvoiceDays(e){
    setSelectedInvoiceDays({ label: e.label, value: e.value });
  }

  function selectCountry(e){
    setSelectedCountry({ label: e.label, value: e.value });
  }


  return (
    <formContext.Provider
      value={{
        selectedBranch,
        selectedDateMonthYear,
        selectedDateMonthYearTmp,
        customerCount,
        umsatz,
        selectedInvoiceDays,
        selectedCountry,
        name,
        email,
        phone,
        adress,
        zipcode,
        place,
        partnerName,
        fileBilanz,
        fileBwa,
        fileSumSal,
        fileOpos,
        stepOneComplete,
        stepTwoComplete,
        stepThreeComplete,
        stepFourComplete,
        stepFiveComplete,
        selectBranch,
        selectDateMonthYear,
        getCustomerCount,
        getUmsatz,
        selectInvoiceDays,
        selectCountry,
        getName,
        getAdress,
        getZipcode,
        getPlace,
        getEmail,
        getPhone,
        getPartnerName,
        getFileBilanz,
        getFileBwa,
        getFileSumSal,
        getFileOpos,
        getStepOneComplete,
        getStepTwoComplete,
        getStepThreeComplete,
        getStepFourComplete,
        getStepFiveComplete

      }}
    >
      {children}
    </formContext.Provider>
  );
};

export default formContext;
