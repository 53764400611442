import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import tebaLogo from "../images/TEBA_Logo_2018.svg";
import 'react-toastify/dist/ReactToastify.css';

function Root() {

  const openDatenschutz = () => {
    document.querySelector('.datenschutzModal').classList.remove('hide');
    document.querySelector('.datenschutzModal').classList.add('showModal');
  };

  const closeDatenschutz = () => {
    document.querySelector('.datenschutzModal').classList.remove('showModal');
    document.querySelector('.datenschutzModal').classList.add('hide');
  };

  return (
    
    <div className='mainContent'>
        <header class="pageheader" >
          <div class="containerHeader">
            <div class="navbar-header">
              <div class="nav-brand"><a href="https://www.teba-kreditbank.de/" target="_blank" rel="noreferrer" title="TEBA Kreditbank - zur Startseite"><img src={tebaLogo} alt="" width="489" height="113" border="0"/></a></div>
              <div class="topnavigation">
                <ul class="topnav"><li class="active"><a href="https://www.teba-kreditbank.de/" target="_blank" rel="noreferrer" title="Startseite">Home</a></li><li><a href="https://www.teba-kreditbank.de/impressum/#c131" target="_blank" rel="noreferrer" title="Impressum">Impressum</a></li><li><a onClick={() => openDatenschutz()} id='datenschutzLink' title="Datenschutz">Datenschutz</a></li><li><a href="https://www.teba-kreditbank.de/glossar" target="_blank" rel="noreferrer" title="Zum Thema Factoring bietet Ihnen unser Lexikon verständliche Erläuterungen kreditwirtschaftlicher Begriffe – von Ausfallrisiko bis Zessionskredit.">Glossar</a></li></ul>
                <div class="kontakticons">
                    <div id="c1" class="frame frame-default frame-type-html frame-layout-0">
            <ul>
          <li><a class="anruf" href="tel:+49995198040"><i class="fas fa-phone"></i></a></li>
          <li><a class="rueckruf" target="_blank" rel="noreferrer" href="https://www.teba-kreditbank.de/rueckrufbitte"><i class="fas fa-phone"></i><i class="fas fa-reply"></i></a></li>
          <li><a class="mail" target="_blank" rel="noreferrer" href="https://www.teba-kreditbank.de/kontakt/kontaktformular"><i class="fas fa-envelope"></i></a></li>
        </ul>
        </div>
        </div>
				<div class="info-hotline hidden-xs">
            <div class="frame frame-default frame-type-text frame-layout-0">
               <p>Info-Hotline:&nbsp;<strong><a href="tel:+49995198040">09951/9804-0</a></strong></p>
            </div>
        </div>
			</div>
		</div>
	</div>
	<div class="navbar navbar-default"></div>
</header>
        <div className="container">
            
            <Header />
            <Outlet />
        </div>

     <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"></link>   

      <div className='hide datenschutzModal'>
        <div className='transparentModalBackground' onClick={() => closeDatenschutz()}></div>
        <div className='modalContainer' >
          <i className='fas fa-xmark' onClick={() => closeDatenschutz()} ></i>

          <div className='modalContent'>
                <p className='datenschutzHeader'>DATENSCHUTZ-ERKLÄRUNG</p>
                <p>Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsführung der TEBA Kreditbank GmbH & Co. KG („TEBA“).</p>
                <p>Um Ihnen ein gutes Gefühl im Hinblick auf den Umgang mit Ihren persönlichen Daten zu geben, machen wir für Sie transparent, was mit den anfallenden Daten geschieht und welche Sicherheitsmaßnahmen unsererseits getroffen werden. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, E-Mail-Adresse oder das Nutzerverhalten. Außerdem werden Sie über Ihre gesetzlich festgelegten Rechte im Zusammenhang mit der Verarbeitung dieser Daten informiert.</p>
                <br></br>
                <p className='smallDatenschutzHeader'>1. VERANTWORTLICHE STELLE UND DATENSCHUTZBEAUFTRAGTER</p>
                <p>Der für die Datenverarbeitung im Sinne des Art. 13 DSGVO „Verantwortliche“ ist die TEBA Kreditbank GmbH & Co. KG, Lindenstraße 5, 94405 Landau a. d. Isar, Telefon +49 9951 9804-0, E-Mail-Adresse: info@teba-kreditbank.de.</p>
                <p>Unser Beauftragter für den Datenschutz ist unter der E-Mail-Adresse datenschutzbeauftragter@teba-kreditbank.de erreichbar.</p>
                <br></br>
                <p>Wir hosten die Inhalte unserer Webseite bei folgendem Anbieter:</p>
                <p>IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (im Folgenden IONOS).</p>
                <p>Details entnehmen Sie der Datenschutzerklärung von IONOS: <a href='https://www.ionos.de/terms-gtc/datenschutzerklaerung/?source=termsandconditions' target='_blank' rel='noreferrer'>https://www.ionos.de/terms-gtc/datenschutzerklaerung/?source=termsandconditions</a>.</p>
                <br></br>
                <p className='smallDatenschutzHeader'>2. INFORMATION ÜBER DIE ERHEBUNG PERSONENBEZOGENER DATEN</p>
                <p>Bei der rein informatorischen Nutzung der Webseite, also wenn Sie uns keine Informationen übermitteln, erheben wir nur diejenigen Daten, die Ihr Browser an den Webserver übermittelt. Dieses sind die IP-Adresse, das Datum und die Uhrzeit des Zugriffes, die angeforderte Webseite (Request), eine statuscodebasierte Meldung, ob der Zugriff erfolgreich war, die übertragene Datenmenge, die Webseite von der Sie auf unsere Webseite gelangt sind oder von der der Aufruf kommt (Referer) sowie Informationen über den Browser und das Betriebssystem des zugreifenden Gerätes (UserAgent).</p>
                <p>Die Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Seitens der TEBA besteht ein berechtigtes Interesse, den nachhaltigen Betrieb der Webseite gewährleisten zu können. Ohne die Verarbeitung dieser Daten ist die Nutzung der Webseite nicht möglich. Eine technische Nutzung dieser Daten auf dem Webserver erfolgt zeitlich beschränkt für die Dauer Ihrer Nutzung der Webseite.</p>
                <p>Bei Ihrer Kontaktaufnahme mit uns über das Kontaktformular oder per direkter E-Mail werden die von Ihnen mitgeteilten Daten (bspw. Name, Vorname, Firma, E-Mail-Adresse und Inhalt der Mitteilung) an uns übertragen und von uns gespeichert. Die Verarbeitung der Daten erfolgt ausschließlich zweckgebunden, um Ihre Fragen zu beantworten oder Ihr Anliegen zu bearbeiten.</p>
                <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
                <p>Die Kontaktaufnahme erfolgt ausdrücklich auf freiwilliger Basis. Ein Ausbleiben der Bereitstellung hat keine Auswirkung für Sie.</p>
                <p>Für die zielführende Bearbeitung der Anliegen kann auf der Grundlage des Art. 28 DSGVO (Auftragsverarbeitung) bzw. Art. 6 Abs. 1 lit. f, ErwG 48 DSGVO (berechtigtes Interesse) eine Weiterleitung der über die Webseite übermittelten Informationen an die zuständigen Stellen und Fachabteilungen innerhalb der TEBA erfolgen.</p>
                <p>Auf der Grundlage des Art. 28 DSGVO (Auftragsverarbeitung) werden zudem externe Dienstleister zur Unterstützung des Betriebs der Webseite eingesetzt. Die Dienstleister sind zur Wahrung der Verschwiegenheit verpflichtet und dürfen Ihre personenbezogenen Daten nur strikt zweckgebunden im Rahmen der Erfüllung des zwischen ihnen und der TEBA geschlossenen Vertrages nutzen.</p>
                <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
                <br></br>
                <p className='smallDatenschutzHeader'>3. COOKIES</p>
                <p>Es werden vereinzelt sogenannte Cookies verwendet. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert, sofern in Ihrem Browser die Aufnahme von Cookies aktiviert ist.</p>
                <p>Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.</p>
                <p>Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar. Es kommen ausschließlich technisch erforderliche Cookies zur Anwendung, die für den Betrieb der Webseite und die Bereitstellung spezifischer Seitenfunktionen technisch notwendig sind. Die Rechtsgrundlage für die Verarbeitung dieser Cookies ist Art. 6 Abs. 1 S. 1 lit. f DSGVO (berechtigtes Interesse). Diese dienen unserem berechtigten Interesse, den nachhaltigen Betrieb und die Funktionsfähigkeit unserer Webseite sicherzustellen.</p>
                <p>Darüber hinaus können Sie Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und bspw. die Annahme von Cookies ganz oder teilweise ablehnen, wenn Sie nicht möchten, dass Cookies auf Ihrem Rechner gespeichert werden. Bereits gespeicherte Cookies können zudem in den Systemeinstellungen Ihres Browsers gelöscht werden. Wir weisen Sie darauf hin, dass die Deaktivierung oder Löschung von Cookies dazu führen kann, dass Sie eventuell nicht alle Funktionen dieser Webseite nutzen können.</p>
                <br></br>
                <p className='smallDatenschutzHeader'>4. IHRE RECHTE</p>
                <p>Sie haben das Recht, Auskunft über die Verarbeitung Sie betreffender personenbezogener Daten sowie die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen zu erhalten. Sollten die betreffenden personenbezogenen Daten unrichtig oder unvollständig sein, können Sie die Berichtigung oder die Vervollständigung dieser Daten verlangen (Art. 16 DSGVO). Liegt einer der in Art. 17 DSGVO aufgeführten Gründe vor, haben Sie das Recht, die unverzügliche Löschung Ihrer personenbezogenen Daten zu verlangen. Unter den Voraussetzungen des Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung verlangen und gemäß Art. 20 DSGVO die Datenübertragbarkeit.</p>
                <p>Unter den Voraussetzungen des Art. 21 DSGVO haben Sie das Recht, Widerspruch gegen eine Verarbeitung Ihrer personenbezogenen Daten einzulegen.</p>
                <p>Sollten Sie der Ansicht sein, dass die Verarbeitung Ihrer personenbezogenen Daten gegen Bestimmungen der DSGVO verstößt, können Sie Beschwerde beim Bayerischen Landesamt für Datenschutzaufsicht, als der zuständigen Aufsichtsbehörde, einreichen (Art. 77 DSGVO).</p>
                <br></br>
                <p className='smallDatenschutzHeader'>5. SICHERHEIT</p>
                <p>Um die bei uns gespeicherten personenbezogenen Daten durch zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder den Zugriff unberechtigter Personen zu schützen, haben wir entsprechende technische und organisatorische Maßnahmen getroffen (bspw. SSL- bzw. TLS-Verschlüsselung). Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden. Die Angemessenheit der Schutzmaßnahmen wird in Zusammenarbeit mit Sicherheitsexperten laufend überprüft und an neue Sicherheitsstandards angepasst.</p>
                <br></br>
                <p className='smallDatenschutzHeader'>6. HINWEIS ZUR DATENWEITERGABE IN DATENSCHUTZRECHTLICH NICHT SICHERE DRITTSTAATEN SOWIE DIE WEITERGABE AN US-UNTERNEHMEN, DIE NICHT DPF-ZERTIFIZIERT SIND</p>
                <p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in datenschutzrechtlich nicht sicheren Drittstaaten sowie US-Tools, deren Anbieter nicht nach dem EU-US-Data Privacy Framework (DPF) zertifiziert sind. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Staaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in datenschutzrechtlich unsicheren Drittstaaten kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Wir weisen darauf hin, dass die USA als sicherer Drittstaat grundsätzlich ein mit der EU vergleichbares Datenschutzniveau aufweisen. Eine Datenübertragung in die USA ist danach zulässig, wenn der Empfänger eine Zertifizierung unter dem „EU-US Data Privacy Framework“ (DPF) besitzt oder über geeignete zusätzliche Garantien verfügt. Informationen zu Übermittlungen an Drittstaaten einschließlich der Datenempfänger finden Sie in dieser Datenschutzerklärung.</p>
                <br></br>

                <p className='datenschutzHeader'>DATENSCHUTZINFORMATIONEN</p>
                <p><a href='https://www.teba-kreditbank.de/fileadmin/user_upload/TEBA_Datenschutzinformation_00402_BP_200217.pdf' target='_noblank' rel='noreferrer' >Datenschutzinformation</a> für potenzielle Kunden und Interessenten </p>
          </div>

        </div>
      </div>

    </div>
    
  )
}


export default Root
