import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import formContext from "../context/form-context";
import moment from 'moment';
import 'moment/locale/de';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import AsyncSelect from 'react-select/async';
import { PeopleOutline, BarChartOutline, CalendarOutline } from 'react-ionicons'

function FactoringInfo() {
  const { getStepOneComplete, selectBranch, selectDateMonthYear, getCustomerCount, getUmsatz, selectInvoiceDays, selectCountry } = useContext(formContext);
  const { selectedBranch, selectedDateMonthYearTmp, customerCount, umsatz, selectedInvoiceDays, selectedCountry } = useContext(formContext);

  var validDate = function( current ){
    return current.isBefore( moment().toDate() );
  };

  const mapResponseToValuesAndLabels = (data) => ({
    value: data.id,
    label: data.name,
  });

  const mapBranchResponseToValuesAndLabels = (data) => ({
    value: data.id,
    label: '['+data.branchKey+'] '+data.name,
  });

  async function loadBranchOptions(value) {
    const data = await fetch('https://antrag-backend.teba-kreditbank.de/public/api/getbranches')
      .then((response) => response.json())
      .then((response) => response.map(mapBranchResponseToValuesAndLabels))
      .then((final) =>
        final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
      );

    return data;
  }

  async function loadInvoiceOptions(value) {
    const data = await fetch('https://antrag-backend.teba-kreditbank.de/public/api/getInvoiceOptions')
      .then((response) => response.json())
      .then((response) => response.map(mapResponseToValuesAndLabels))
      .then((final) =>
        final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
      );

    return data;
  }

  async function loadCountryOptions(value) {
    const data = await fetch('https://antrag-backend.teba-kreditbank.de/public/api/getCountryOptions')
      .then((response) => response.json())
      .then((response) => response.map(mapResponseToValuesAndLabels))
      .then((final) =>
        final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
      );

    return data;
  }

  const navigate = useNavigate();

  
  function submitHandler(e){
    e.preventDefault();
    
    const inputs = document.querySelectorAll('input');
    let validArr = [];
    inputs.forEach(input => {
      console.log(input);
      
      if(input.id.includes('react-select-') === false){
        if(input.value === ''){
          input.style.borderColor = 'var(--strawberry-red)';
        } else {
          validArr.push('valid')
          input.style.borderColor = 'var(--valid-green)';
        }
      }
    })

    if(selectedBranch != null){
      validArr.push('valid');
      document.querySelector('#select-branch-input-teba').style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#select-branch-input-teba').style.borderColor = 'var(--strawberry-red)';
    } 

    if(selectedInvoiceDays != null){
      validArr.push('valid');
      document.querySelector('#select-invoice').style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#select-invoice').style.borderColor = 'var(--strawberry-red)';
    }

    if(selectedCountry != null){
      validArr.push('valid');
      document.querySelector('#select-country').style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#select-country').style.borderColor = 'var(--strawberry-red)';
    }

    console.log(validArr);
    
    if(validArr.length >= 6){
      getStepOneComplete();
      navigate('/factoring-kond');
    }
  }

  return <main className="form-container">
    <form className="form" onSubmit={submitHandler}>
      <div className="form-data">
        <h1>Starten Sie jetzt mit Ihrem Factoring-Antrag!</h1>
        <p>Bitte erfassen Sie ausgewählte Angaben zu Ihrem Factoring-Vorhaben.</p>
        <div className="input-div">
          <label htmlFor="branche"><span>Branche*</span> <span>&nbsp;</span></label>
          <AsyncSelect cacheOptions className="select-input-teba" id="select-branch-input-teba" onChange={selectBranch} loadOptions={loadBranchOptions} defaultOptions placeholder="Bitte auswählen" value={selectedBranch}/>
        </div>
        <div className="input-div">
          <label ><span>Firmensitz*</span> <span>&nbsp;</span></label>
          <AsyncSelect className="select-input-teba" id="select-country" onChange={selectCountry} loadOptions={loadCountryOptions} defaultOptions placeholder="Bitte auswählen" value={selectedCountry}/>
        </div>
        <div className="input-div">
          <label ><span>Gründungsjahr / Monat*</span> <span>&nbsp;</span></label>
          <Datetime dateFormat="MMMM YYYY" closeOnSelect="true" locale="de" timeFormat={false} onChange={selectDateMonthYear} value={selectedDateMonthYearTmp} isValidDate={ validDate } />
          <CalendarOutline cssClasses="inputIcon" title={"CalendarIcon"} />
        </div>
        <div className="input-div">
          <label ><span>Anzahl Kunden*</span> <span>&nbsp;</span></label>
          <input type="number" onChange={getCustomerCount} value={customerCount}/>
          <PeopleOutline cssClasses="inputIcon" title={"PeopleIcon"} />
        </div>
        <div className="input-div">
          <label ><span>Vorjahresumsatz in €*</span> <span>&nbsp;</span></label>
          <input type="number" onChange={getUmsatz} value={umsatz}/>
          <BarChartOutline cssClasses="inputIcon" title={"PeopleOutline"} /> 
        </div>
        <div className="input-div">
          <label ><span>Durchschnittliche Zahldauer Ihrer Rechnungen*</span> <span>&nbsp;</span></label>
          <AsyncSelect className="select-input-teba" id="select-invoice" onChange={selectInvoiceDays} loadOptions={loadInvoiceOptions} defaultOptions placeholder="Bitte auswählen" value={selectedInvoiceDays}/>
        </div>
        <p className="warningMandatory">Alle mit * gekennzeichneten Felder sind Pflichtfelder!</p>
      </div>
      <div className="form-submit">
        <div>&nbsp;</div>
        <button type="submit" className="submit-btn">Weiter</button>
      </div>
    </form>
  </main>
}

export default FactoringInfo;
