import { useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import formContext from "../context/form-context";
import axios from 'axios';


function Overview() {

  const openDatenschutz = () => {
    document.querySelector('.datenschutzModal').classList.remove('hide');
    document.querySelector('.datenschutzModal').classList.add('showModal');
  };

  const closeDatenschutz = () => {
    document.querySelector('.datenschutzModal').classList.remove('showModal');
    document.querySelector('.datenschutzModal').classList.add('hide');
  };

  const { getStepFiveComplete, name, adress, zipcode, place, email, phone, partnerName, selectedBranch, selectedCountry, selectedDateMonthYear, customerCount, umsatz, selectedInvoiceDays, fileBilanz, fileBwa, fileSumSal, fileOpos} = useContext(formContext);
  console.log(fileBilanz);
  const navigate = useNavigate();

  if(customerCount === ''){
    navigate('/');
  } 

  var tmpDate = new Date(selectedDateMonthYear.year, selectedDateMonthYear.month, 1);
  var monthString = tmpDate.toLocaleString('de-de', {month: 'long'});

  function submitHandler(e){
    e.preventDefault();

    const inputs = document.querySelectorAll('input');
    let validArr = [];
    inputs.forEach(input => {
      console.log(input.checked);
      if(input.checked === false){
        input.parentElement.style.borderColor = 'var(--strawberry-red)';
      } else {
        validArr.push('valid')
        input.parentElement.style.borderColor = 'var(--valid-green)';
      }
    })

    console.log(validArr);
    console.log(fileBilanz);
    console.log(fileBwa);
    console.log(fileOpos);
    console.log(fileSumSal);
    
    if(validArr.length >= 2){
      // Send to API
      
      document.querySelector('.spinnerPlaceholder').classList.add('fa-spinner');

      const formData = new FormData();
      formData.append('companyName', name);
      formData.append('address', adress);
      formData.append('country', selectedCountry['value']);
      formData.append('email', email);
      formData.append('telephone', phone);
      formData.append('zipcode', zipcode);
      formData.append('city', place);
      formData.append('contactperson', partnerName);
      formData.append('foundingDate', selectedDateMonthYear['year'] + '-' + selectedDateMonthYear['month']);
      formData.append('customerAmount', customerCount);
      formData.append('salesAmount', umsatz);
      formData.append('branch', selectedBranch['value']);
      formData.append('invoiceDays', selectedInvoiceDays['value']);
      formData.append('fileBilanz', fileBilanz[0]);
      formData.append('fileBwa', fileBwa[0]);
      formData.append('fileOpos', fileOpos[0]);
      formData.append('fileSumSal', fileSumSal[0]);

      try {
        axios({
          method: "post",
          url: 'https://antrag-backend.teba-kreditbank.de/public/api/insertrequest',
          data: formData,
          headers: { "Content-Type": "multipart/form-data"},
        })
        .then(function (response) {
          getStepFiveComplete();
          navigate('/thank-you');
        })
        .catch(function (error) {
          console.log(error);
        });
      } catch(error) {
        console.log(error);
      }


      /*axios.post('http://localhost:8000/api/insertrequest', {
        companyName: {name},
        address: {adress},
        email: {email},
        telephone: {phone},
        zipcode: '00000',
        city: 'TestCity',
        contactperson: {partnerName},
        foundingDate: {selectedDateMonthYear},
        customerAmount: {customerCount},
        salesAmount: {umsatz},
        branch: {selectedBranch},
        invoiceDays: {selectedInvoiceDays}
      })
      .then(function (response) {
        getStepFiveComplete();
        navigate('/thank-you');
      })
      .catch(function (error) {
        console.log(error);
      });*/

    }
  }

  return (
    <main className="form-container">
        <form className="form" onSubmit={submitHandler}>
          <div className="form-data">
            <h1>Zusammenfassung</h1>
            <p>Bitte überprüfen Sie Ihre Angaben.</p>
            <div className='overview-div'>
              <div>
                <div className="overview-plan">
                  <div>
                    <p><b>Firmenname:</b> {name}</p>
                    <p><b>Addresse:</b> {adress}</p>
                    <p><b>PLZ:</b> {zipcode}</p>
                    <p><b>Ort:</b> {place}</p>
                    <p><b>Land:</b> {selectedCountry.label}</p>
                    <p><b>E-Mail:</b> {email}</p>
                    <p><b>Telefon:</b> {phone}</p>
                    <p><b>Ansprechpartner:</b> {partnerName}</p>
                    <p><b>Ausgewählte Branche:</b> {selectedBranch.label}</p>
                    <p><b>Gründungsjahr / Monat:</b> {monthString} {selectedDateMonthYear.year}</p>
                    <p><b>Anzahl Kunden:</b> {customerCount}</p>
                    <p><b>Vorjahresumsatz:</b> {umsatz} €</p>
                    <p><b>Durchschnittliche Zahldauer Ihrer Rechnungen:</b> {selectedInvoiceDays.label}</p>
                  </div> 
                </div>
              </div>
              <ul>
              <div className="overview-documents">
                  <p><b>Bilanz letztes Geschäftsjahr:</b> {fileBilanz[0].name}</p>
                  <p><b>Betriebswirtschaftliche Auswertung (BWA):</b> {fileBwa[0].name}</p>
                  <p><b>Summen- und Saldenliste:</b> {fileSumSal[0].name}</p>
                  <p><b>Offene-Posten-Liste:</b> {fileOpos[0].name}</p>
                </div>                
              </ul>
            </div>  
            <div className='checkbox-flex'> 
              <input type="checkbox" id="agb-checkbox" name="Online service" />
              <label htmlFor="agb-checkbox" className='add-on-checkbox'>
                <div>
                  <h3>Ich habe die <a href="https://www.teba-kreditbank.de/impressum/#c131" target="_blank" rel="noreferrer">Nutzungsbedingungen</a> gelesen und akzeptiert.*</h3>
                  <p className='small'></p>
                </div>
              </label>
            </div>
            <div className='checkbox-flex'> 
              <input type="checkbox" id="dsgvo-checkbox" name="Online service" />
              <label htmlFor="dsgvo-checkbox" className='add-on-checkbox'>
                <div>
                  <h3>Ich habe die <a onClick={() => openDatenschutz()} target="_blank" rel="noreferrer">Datenschutzerklärung</a> gelesen und akzeptiert.*</h3>
                  <p className='small'></p>
                </div>
              </label>
            </div>
            <p className="warningMandatory">Alle mit * gekennzeichneten Felder sind Pflichtfelder!</p>
          </div>
          <div className="form-submit">
            <div><Link to='/documents' className="cool-gray">Zurück</Link></div>
            <button type="submit" className="submit-btn confirm-btn" ><i className='fas spinnerPlaceholder'></i> Antrag absenden</button>
          </div>
        </form>
      </main>
  )
}

export default Overview
