import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import formContext from "../context/form-context";
import { DocumentText } from 'react-ionicons'
import { useDropzone } from 'react-dropzone'

function Documents() {  
  const { getStepFourComplete, getFileBilanz, getFileBwa, getFileSumSal, getFileOpos, fileBilanz, fileBwa, fileSumSal, fileOpos, customerCount } = useContext(formContext);

  var {acceptedFiles:acceptedFileBilanz, getRootProps:getRootPropsBilanz, getInputProps:getInputPropsBilanz} = GetDropzoneInit();
  var {acceptedFiles:acceptedFileBwa, getRootProps:getRootPropsBwa, getInputProps:getInputPropsBwa} = GetDropzoneInit();
  var {acceptedFiles:acceptedFileSumSal, getRootProps:getRootPropsSumSal, getInputProps:getInputPropsSumSal} = GetDropzoneInit();
  var {acceptedFiles:acceptedFileOpos, getRootProps:getRootPropsOpos, getInputProps:getInputPropsOpos} = GetDropzoneInit();

  if(fileBilanz !== ""){
    acceptedFileBilanz = fileBilanz;
  }

  if(fileBwa !== ""){
    acceptedFileBwa = fileBwa;
  }

  if(fileSumSal !== ""){
    acceptedFileSumSal = fileSumSal;
  }

  if(fileOpos !== ""){
    acceptedFileOpos = fileOpos;
  }

  const fileBilanzName = mapFiles(acceptedFileBilanz);
  const fileBwaName = mapFiles(acceptedFileBwa);
  const fileSumSalName = mapFiles(acceptedFileSumSal);
  const fileOposName = mapFiles(acceptedFileOpos);

  const navigate = useNavigate();

  if(customerCount === ''){
    navigate('/');
  } 

  function GetDropzoneInit(){
    return useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/heic': ['.heic'],
        'application/pdf': ['.pdf'],
      },
      multiple: false,
    });
  }

  function mapFiles(tmpFile){
    if(tmpFile === undefined){
    
    }else{
      return tmpFile.map(file => (
        <li key={file.path}>
          {file.path} - {(Math.round((file.size/(1024*1024)) * 100) / 100).toFixed(2)} MB
        </li>
      ));
    }
  }

  function submitHandler(e) {
    e.preventDefault();

    /*const inputs = document.querySelectorAll('input');*/
    let validArr = [];
    /*inputs.forEach(input => {
      console.log("input");
      console.log(input);
      console.log("value");
      console.log(input.value);
      console.log("files");
      console.log(input.files);
      console.log("files 0");
      console.log(input.files[0]);
      console.log("file list length");
      console.log(input.files.length);
      console.log("__________________");
      console.log(acceptedFileBilanz);
      console.log(acceptedFileBwa);
      console.log(acceptedFileSumSal);
      console.log(acceptedFileOpos);
      if(input.files.length == 0){
        input.parentNode.style.borderColor = 'var(--strawberry-red)';
      } else {
        validArr.push('valid')
        input.parentNode.style.borderColor = 'var(--valid-green)';
      }
    })*/

    if(acceptedFileBilanz.length > 0) {
      validArr.push('valid');
      document.querySelector('#fileInpBil').parentNode.style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#fileInpBil').parentNode.style.borderColor = 'var(--strawberry-red)';
    }

    if(acceptedFileBwa.length > 0) {
      validArr.push('valid');
      document.querySelector('#fileInpBwa').parentNode.style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#fileInpBwa').parentNode.style.borderColor = 'var(--strawberry-red)';
    }

    if(acceptedFileSumSal.length > 0) {
      validArr.push('valid');
      document.querySelector('#fileInpSumSal').parentNode.style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#fileInpSumSal').parentNode.style.borderColor = 'var(--strawberry-red)';
    }

    if(acceptedFileOpos.length > 0) {
      validArr.push('valid');
      document.querySelector('#fileInpOpos').parentNode.style.borderColor = 'var(--valid-green)';
    }else{
      document.querySelector('#fileInpOpos').parentNode.style.borderColor = 'var(--strawberry-red)';
    }

    console.log(validArr);
    
    if(validArr.length >= 4){
      getFileBilanz(acceptedFileBilanz);
      getFileBwa(acceptedFileBwa);
      getFileSumSal(acceptedFileSumSal);
      getFileOpos(acceptedFileOpos);

      getStepFourComplete();

      navigate('/overview');
    }
  }


  return (
      <main className="form-container">
        <form className="form" onSubmit={submitHandler}>
          <div className="form-data">
            <h1>Dokumente</h1>
            <p>Bitte laden Sie folgende Unterlagen hoch:</p>
            <p>Folgende Datei-Typen sind erlaubt: .png, .jpg, .jpeg, .heic, .pdf</p>

            <div className="document-card">
              <div className="document-info-box">
                <DocumentText cssClasses="documentIcon" title={"DocumentIcon"} />
                <div className="document-info-text">
                  <h3>Bilanz letztes Geschäftsjahr*</h3>
                  <p>Bitte laden Sie hier Ihre letzte Bilanz hoch.</p>
                </div>
              </div>
              <div className="document-file-upload-box">
                <section>
                  <div {...getRootPropsBilanz({className: 'dropzone'})}>
                    <input id="fileInpBil" {...getInputPropsBilanz()} />
                    <p>Datei auswählen oder per Drag & Drop</p>
                  </div>
                  <aside>
                    <ul>{fileBilanzName}</ul>
                  </aside>
                </section>
              </div>
            </div>

            <div className="document-card">
              <div className="document-info-box">
                <DocumentText cssClasses="documentIcon" title={"DocumentIcon"} />
                <div className="document-info-text">
                  <h3>Aktuellste Betriebswirtschaftliche Auswertung (BWA)*</h3>
                  <p>Bitte laden Sie hier Ihre aktuellste Betriebswirtschaftliche Auswertung hoch.</p>
                </div>
              </div>
              <div className="document-file-upload-box">
                <section>
                  <div {...getRootPropsBwa({className: 'dropzone'})}>
                    <input id="fileInpBwa" {...getInputPropsBwa()} />
                    <p>Datei auswählen oder per Drag & Drop</p>
                  </div>
                  <aside>
                    <ul>{fileBwaName}</ul>
                  </aside>
                </section>
              </div>
            </div>

            <div className="document-card">
              <div className="document-info-box">
                <DocumentText cssClasses="documentIcon" title={"DocumentIcon"} />
                <div className="document-info-text">
                  <h3>Aktuellste Summen- und Saldenliste*</h3>
                  <p>Bitte laden Sie hier Ihre Summen- und Saldenliste hoch.</p>
                </div>
              </div>
              <div className="document-file-upload-box">
                <section>
                  <div {...getRootPropsSumSal({className: 'dropzone'})}>
                    <input id="fileInpSumSal" {...getInputPropsSumSal()} />
                    <p>Datei auswählen oder per Drag & Drop</p>
                  </div>
                  <aside>
                    <ul>{fileSumSalName}</ul>
                  </aside>
                </section>
              </div>
            </div>

            <div className="document-card">
              <div className="document-info-box">
                <DocumentText cssClasses="documentIcon" title={"DocumentIcon"} />
                <div className="document-info-text">
                  <h3>Aktuellste Offene-Posten-Liste*</h3>
                  <p>Bitte laden Sie hier Ihre OPOS-Liste hoch, welche alle noch nicht ausgeglichenen Buchungen enthält.</p>
                </div>
              </div>
              <div className="document-file-upload-box">
                <section>
                  <div {...getRootPropsOpos({className: 'dropzone'})}>
                    <input id="fileInpOpos" {...getInputPropsOpos()} />
                    <p>Datei auswählen oder per Drag & Drop</p>
                  </div>
                  <aside>
                    <ul>{fileOposName}</ul>
                  </aside>
                </section>
              </div>
            </div>

            <p className="warningMandatory">Alle mit * gekennzeichneten Felder sind Pflichtfelder!</p>

            
          </div>
          <div className="form-submit">
            <div><Link to='/business-info' className="cool-gray">Zurück</Link></div>
            <button type="submit" className="submit-btn">Weiter</button>
          </div>
        </form>
      </main>
  ) 
}

export default Documents;