import { useContext } from 'react';
import checkMark from '../images/icon-thank-you-teba.svg';
import { useNavigate } from "react-router-dom";
import formContext from "../context/form-context";

function ThankYou() {
  const { customerCount } = useContext( formContext );

  const navigate = useNavigate();

  if(customerCount === ''){
    navigate('/');
  }  

  return (
    <main className="form-container thank-you-container">
      <div className="form-data thank-you-data">
        <img src={checkMark} alt="checkmark" />
        <h1>Vielen Dank!</h1>
        <p>Die Übermittlung Ihres Factoring-Antrags war erfolgreich.</p>
        <p>Ein unverbindliches Angebot wird Ihnen innerhalb der nächsten zwei Werktage von unserer Factoring-Abteilung zugesendet.</p>
      </div>
    </main>
  )
}

//<p>Sie erhalten in Kürze eine Bestätigungs E-Mail mit Ihren übermittelten Daten an {email}.</p>

export default ThankYou